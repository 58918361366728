const CheckToken = () => {
  var token = localStorage.getItem("token");
  if (!token) {
    return null;
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

export default CheckToken;
