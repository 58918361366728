import React, { useState, useRef, useEffect } from "react";
//import "./CallLogForm.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { set } from "date-fns";

export default function AddInfoCallLead({ edit }) {
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [confirmed, setConfirmed] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [outcome, setOutcome] = useState("");
  const [id, setId] = useState(null); // used for edit

  const navigate = useNavigate();

  const inputRef = useRef(null);

  // Use useEffect to focus the input field when the component mounts
  useEffect(() => {
    if(edit === 'true') {
      const id = window.location.pathname.split("/").pop();// get last segment of the url
      if (!id ||  id <= 0) {
        setError("id required");
        return;
      }
      console.log("edit is true");
      const url = `${process.env.REACT_APP_API_URL}/infocalllead/${id}`;
      console.log("api url", url);
      var token = localStorage.getItem("token");
      if (!token) {
        navigate("/login", { replace: true });
      }
  
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      axios
        .get(url, { headers })
        .then((res) => {
          console.log(res);
          if (res.status !== 200) {
            setError("infocalllead not found");
            return;
          } else {
            setError(null);
            setName(res.data.name);
            setEnquiry(res.data.enquiry);
            setOutcome(res.data.outcome);
            setPhoneNumber(res.data.phone_number);
            setEmail(res.data.email);
            setId(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
          setError(err.message);        
        });
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  function validate(params) {
    if (!name) {
      setError("name required");
      return false;
    }

    if (!enquiry) {
      setError("enquiry required");
      return false;
    }

    if (!outcome) {
      setError("outcome required");
      return false;
    }

    if (!phoneNumber) {
      setError("mobile number required");
      return false;
    }

    if (!email) {
      setError("email required");
      return false;
    }

    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    var token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", { replace: true });
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const postData = {
      firstname: name,
      enquiry: enquiry,
      outcome: outcome,
      phonenumber: phoneNumber,
      email: email,
    };

    var url = `${process.env.REACT_APP_API_URL}/addinfocalllead`;
    console.log("api url", url);
    if (edit === 'true') {
      url = `${process.env.REACT_APP_API_URL}/editinfocalllead/${id}`;
    }

    axios
      .post(url, postData, { headers })
      .then((res) => {
        console.debug(res);
        if (res.status !== 200) {
          setError("infocalllead not inserted");
          return;
        } else {
          setError(null);
          setConfirmed("lead inserted");
          navigate("/infocallleads", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);        
      });
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <h1 className="heading">Add Info Call Lead</h1>
        <div className="error">{error}</div>
        <div className="confirmed">{confirmed}</div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="form-group">
            <label htmlFor="name" style={{ textAlign: "left", color: "green" }}>
              Name :
            </label>
            <input
              className="form-control"
              id="name"
              value={name}
              placeholder="Enter Name"
              ref={inputRef}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="enquiry"
              style={{ textAlign: "left", color: "green" }}
            >
              Enquiry
            </label>
            <textarea
              placeholder="Enter Enquiry"
              value={enquiry}
              onChange={(e) => setEnquiry(e.target.value)}
            ></textarea>
          </div>

          <div className="form-group">
            <label
              htmlFor="outcome"
              style={{ textAlign: "left", color: "green" }}
            >
              Outcome:
            </label>
            <input
              className="form-control"
              id="outcome"
              placeholder="Enter Outcome"
              value={outcome}
              onChange={(e) => setOutcome(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="mobilenumber"
              style={{ textAlign: "left", color: "green" }}
            >
              Mobile Number:
            </label>
            <input
              className="form-control"
              id="mobilenumber"
              placeholder="Enter Mobile Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="exampleInputEmail1"
              style={{ textAlign: "left", color: "green" }}
            >
              Email address :
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button 
          onClick={() => navigate(-1)}
          className="btn btn-secondary">Cancel</button>
          &nbsp;
          <button type="submit" className="btn btn-success">
            {edit ? 'Updated Info Call Lead':'Create Info Call Lead'}
          </button>
        </form>
      </div>
    </>
  );
}
