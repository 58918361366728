import React from "react";
import Home from "../components/Home";
import Sidebar from "../components/Sidebar";
import NavBar from "../components/NavBar";

// reference:https://www.youtube.com/watch?v=2h1w-auaNCA&t=243s

export default function Dashboard() {
  return (
    <>
      <NavBar />
      <Home />
      {/* <div className="container-fluid bg-dark min-vh-100">
        <div className="row">
          <div className="col-2 bg-white vh-100">
            <Sidebar />
            <Navbar />
          </div>
          <div className="col-10">
            <Home />
          </div>
        </div>
      </div> */}
    </>
  );
}
