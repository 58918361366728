import { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap-v5";

const NavBarUser = () => {

  const [username, setUsername] = useState('');

  useEffect(() => {
    setUsername(localStorage.getItem('userName'));
  }, []);

  return (
    <Navbar bg="light" expand="lg" className="mynav">
      <Navbar.Brand href="#">DHF Member {username}</Navbar.Brand>
      <Nav.Link href="/logout" style={{marginRight: "9px"}}>Logout</Nav.Link>
    </Navbar>

  );
};
export default NavBarUser;
