import Dropdown from "react-bootstrap/Dropdown";
import { getStatusDropDown } from "../../model/UserProfile";
import { useEffect, useState } from "react";

function StatusEdit({ statusCallBack, role_id }) {
  const [display, setDisplay] = useState("Status");
  const [color, setColor] = useState("secondary");

  // Set the initial display and color based on role_id or status on component mount
  useEffect(() => {
    const statusOptions = getStatusDropDown();
    const currentItem = statusOptions.find((item) => item.value === role_id);

    if (currentItem) {
      setDisplay(currentItem.text);
      setColor(currentItem.color);
    }
  }, [role_id]); // Run effect when the role_id changes

  return (
    <Dropdown>
      <Dropdown.Toggle variant={color} id="dropdown-basic">
        {display}
      </Dropdown.Toggle>

      <Dropdown.Menu className="statusDropDown">
        {getStatusDropDown().map((item) => {
          return (
            <Dropdown.Item
              key={item.value}
              onClick={() => {
                statusCallBack(item.value); // Trigger the status change callback
                setDisplay(item.text); // Update the display text
                setColor(item.color); // Update the button color
              }}
            >
              {item.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default StatusEdit;
