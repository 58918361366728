import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap-v5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPlus,
  faVolumeControlPhone,
  faPhone,
  faExpandAlt,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

export default function ExpandableRow({
  lead,
  lastpage,
  addCallLogOpenPopup,
  handleDelete,
}) {
  const [length, setLength] = useState(50);

  const handleClick = () => {
    const callLogsURL = `${process.env.REACT_APP_URL}/calllogs/${lead.id}?lastpage=${lastpage}&name=${lead.firstname}`;
    console.log("Call Logs URL", callLogsURL);
    window.location.href = callLogsURL;
  };

  const handleAdd = () => {
    addCallLogOpenPopup(lead);
  };

  const handlerDelete = (lead_id, lead_name) => {
    handleDelete(lead_id, lead_name); // let parent component handle the delete to refresh list after delete
  };

  return (
    <tr key={lead.id}>
      <td>{lead.firstname}</td>
      <td>{lead.email}</td>
      <td>{lead.mobilenumber}</td>
      <td>
        {lead.call_logs_count ? (
          <FontAwesomeIcon
            icon={faVolumeControlPhone}
            className="btn btn-success btn-sm"
            onClick={() => handleClick()}
          >
            <i className="bi bi-chat-left-dots"></i>
          </FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon
            icon={faPhone}
            className="btn btn-secondary btn-sm"
            onClick={() => handleAdd()}
          >
            +
          </FontAwesomeIcon>
        )}
      </td>
      <td>{lead.profession}</td>
      <td>
        {lead.message ? lead.message.substring(0, length) : ""}
        {lead.message && lead.message.length > 50 ? (
          <FontAwesomeIcon
            icon={faEllipsisH}
            style={{ float: "right" }}
            className="btn btn-sm btn-success"
            onClick={() => {
              {
                length == 50 ? setLength(600) : setLength(50);
              }
            }}
          >
            ...
          </FontAwesomeIcon>
        ) : null}
      </td>
      <td>
        <Button
          onClick={(e) => handlerDelete(lead.id, lead.firstname)}
          style={{
            background: "none",
            color: "gray",
            padding: "1px",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faTrash} className="deleteButton" />
        </Button>
      </td>
    </tr>
  );
}
