import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { set } from "date-fns";
import { Button } from "react-bootstrap-v5";

export default function Interview({}) {
  const { id, name } = useParams(); // from url path /interview/:id/:name
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  // faimly background state
  const [famBack, setFamBack] = useState({
    familyinfo: "",
    bornandraised: "",
    lived: "",
  });
  const [statusFamBack, setStatusFamBack] = useState("");
  const [errorFamBack, setErrorFamBack] = useState("");

  // relationship background state
  const [relBack, setRelBack] = useState({
    seriousrelationship: "",
    breakdownreason: "",
    openandtransparent: "",
  });
  const [statusRelBack, setStatusRelBack] = useState("");
  const [errorRelBack, setErrorRelBack] = useState("");
  // Personal background state

  const [perBack, setPerBack] = useState({
    special: "",
    view: "",
    expectation: "",
    ownqualities: "",
    areas_to_improve: "",
    crossing_the_line: "",
    other_details: "", // consultant's observation
    love_lang: "", // Physical touch and affection
    love_lang_2: "", // Receiving gifts
    love_lang_3: "", // Words of affirmation
    love_lang_4: "", // Quality time
    love_lang_5: "", // Acts of service
  });

  const [statusPerBack, setStatusPerBack] = useState("");
  const [errorPerBack, setErrorPerBack] = useState("");

  useEffect(() => {
    setStatusFamBack("laoding...");
    setErrorFamBack("");
    var token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    loadFamilyBackground(headers);
    loadRelationshipBackground(headers);
    loadPersonalBackground(headers);
  }, []);

  const loadFamilyBackground = (headers) => {
    var url1 = `${process.env.REACT_APP_API_URL}/getfamilybackground/${id}`;
    console.log("api url", url1);
    axios
      .get(url1, { headers })
      .then((res) => {
        console.log(res.data);
        setFamBack(res.data);
        setStatusFamBack("loaded");
        setErrorFamBack("");
      })
      .catch((err) => {
        console.log(err);
        setErrorFamBack("error loading data");
        setStatusFamBack("");
      });
  };

  const loadRelationshipBackground = (headers) => {
    var url = `${process.env.REACT_APP_API_URL}/getrelationshipbackground/${id}`;
    console.log("api url", url);
    axios
      .get(url, { headers })
      .then((res) => {
        console.log(res.data);
        setRelBack(res.data);
        setStatusRelBack("loaded");
        setErrorRelBack("");
      })
      .catch((err) => {
        console.log(err);
        setErrorRelBack("error loading data");
        setStatusRelBack("");
      });
  };

  const loadPersonalBackground = (headers) => {
    var url1 = `${process.env.REACT_APP_API_URL}/getpersonalbackground/${id}`;
    console.log("api url", url1);
    axios
      .get(url1, { headers })
      .then((res) => {
        console.log(res.data);
        setPerBack(res.data);
        setStatusPerBack("loaded");
        setErrorPerBack("");
      })
      .catch((err) => {
        console.log(err);
        setErrorPerBack("error loading data");
        setStatusPerBack("");
      });
  };

  const handleSaveFamilyBackgroundAnswers = () => {
    console.log(famBack.familyinfo);
    var token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const postData = {
      familyinfo: famBack.familyinfo,
      bornandraised: famBack.bornandraised,
      lived: famBack.lived,
    };
    var url = `${process.env.REACT_APP_API_URL}/savefamilybackground/${id}`;
    console.log("api url", url);
    setStatusFamBack("saving...");
    setErrorFamBack("");
    axios
      .post(url, postData, { headers })
      .then((res) => {
        console.log(res.data);
        setStatusFamBack("saved");
        setErrorFamBack("");
      })
      .catch((err) => {
        console.log(err);
        setErrorFamBack("error saving data");
        setStatusFamBack("");
      });
  };

  const handleSaveRelationshipBackgroundAnswers = () => {
    console.log(famBack.familyinfo);
    var token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const postData = {
      seriousrelationship: relBack.seriousrelationship,
      breakdownreason: relBack.breakdownreason,
      openandtransparent: relBack.openandtransparent,
    };
    var url = `${process.env.REACT_APP_API_URL}/saverelationshipbackground/${id}`;
    console.log("api url", url);
    setStatusRelBack("saving...");
    setErrorRelBack("");
    axios
      .post(url, postData, { headers })
      .then((res) => {
        console.log(res.data);
        setStatusRelBack("saved");
        setErrorRelBack("");
      })
      .catch((err) => {
        console.log(err);
        setErrorRelBack("error saving data");
        setStatusRelBack("");
      });
  };

  const handleSubmit = () => {
    handleSaveFamilyBackgroundAnswers();
    handleSaveRelationshipBackgroundAnswers();
    var token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const postData = {
      special: perBack.special,
      view: perBack.view,
      expectation: perBack.expectation,
      ownqualities: perBack.ownqualities,
      areas_to_improve: perBack.areas_to_improve,
      crossing_the_line: perBack.crossing_the_line,
      other_details: perBack.other_details,
      love_lang: perBack.love_lang,
      love_lang_2: perBack.love_lang_2,
      love_lang_3: perBack.love_lang_3,
      love_lang_4: perBack.love_lang_4,
      love_lang_5: perBack.love_lang_5,
    };
    var url = `${process.env.REACT_APP_API_URL}/savepersonalbackground/${id}`;
    console.log("api url", url);
    setStatusPerBack("saving...");
    setErrorPerBack("");
    axios
      .post(url, postData, { headers })
      .then((res) => {
        console.log(res.data);
        setStatusPerBack("saved");
        setErrorPerBack("");
      })
      .catch((err) => {
        console.log(err);
        setErrorPerBack("error saving data");
        setStatusPerBack("");
      });
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <button onClick={handleBack} style={{ float: "right" }}>
          back
        </button>
        <h2 className="heading">{name}</h2>
        <h3 style={{ float: "left" }} className="heading">
          I. Family Background
        </h3>
        <br />
        <br />

        {/* Family Background */}
        <div className="row">
          <div className="col-md-6">
            <h5>1.Can you tell me more about the family you grew up in?</h5>
            <textarea
              onChange={(e) =>
                setFamBack({ ...famBack, familyinfo: e.target.value })
              }
              value={famBack.familyinfo}
            ></textarea>
          </div>

          <div className="col-md-6">
            <h5>2. where were you born and raised?</h5>
            <textarea
              onChange={(e) =>
                setFamBack({ ...famBack, bornandraised: e.target.value })
              }
              value={famBack.bornandraised}
            ></textarea>
          </div>

          <div className="col-md-6">
            <h5>3. Have you lived anywhere else?</h5>
            <textarea
              onChange={(e) =>
                setFamBack({ ...famBack, lived: e.target.value })
              }
              value={famBack.lived}
            ></textarea>
          </div>

          <div className="col-md-6">
            <button
              style={{ marginTop: 60 }}
              onClick={handleSaveFamilyBackgroundAnswers}
            >
              Save
            </button>
            <div className="status">{statusFamBack}</div>
            <div className="error">{errorFamBack}</div>
          </div>
        </div>
        <hr className="heading"></hr>

        {/* Relationship background */}
        <h3 style={{ float: "left" }} className="heading">
          II. Relationship Background
        </h3>
        <br />
        <br />
        <div className="row">
          <div className="col-md-6">
            <h5>
              4. How long has it been since your last serious relationship?
            </h5>
            <textarea
              lines="1"
              onChange={(e) =>
                setRelBack({ ...relBack, seriousrelationship: e.target.value })
              }
              value={relBack.seriousrelationship}
            ></textarea>
          </div>

          <div className="col-md-6">
            <h5>
              5. What was the main reason for that relationship / marriage to
              breakdown?
            </h5>
            <textarea
              onChange={(e) =>
                setRelBack({ ...relBack, breakdownreason: e.target.value })
              }
              value={relBack.breakdownreason}
            ></textarea>
          </div>

          <div className="col-md-6">
            <h5>
              6. Is there anything about you specifically that you'd like to
              share in advance in order to be open and transparent?
            </h5>
            <textarea
              onChange={(e) =>
                setRelBack({ ...relBack, openandtransparent: e.target.value })
              }
              value={relBack.openandtransparent}
            ></textarea>
          </div>

          <div className="col-md-6">
            <button
              style={{ marginTop: 60 }}
              onClick={handleSaveRelationshipBackgroundAnswers}
            >
              Save
            </button>
            <div className="status">{statusRelBack}</div>
            <div className="error">{errorRelBack}</div>
          </div>
        </div>
        <hr className="heading"></hr>

        {/* Personal background, info and preference */}
        <h3 style={{ float: "left" }} className="heading">
          III. Personal background, info and preference
        </h3>
        <br />
        <br />
        <div className="row">
          <div className="col-md-6">
            <h5>
              7. Do you have any special skills/ talents / interests/ hobbies /
              sports that not many people know about?
            </h5>
            <textarea
              onChange={(e) =>
                setPerBack({ ...perBack, special: e.target.value })
              }
              value={perBack.special}
            ></textarea>
          </div>

          <div className="col-md-6">
            <h5>
              8. If I were to ask your best friends, how would they describe
              your personality ?
            </h5>
            <textarea
              onChange={(e) => setPerBack({ ...perBack, view: e.target.value })}
              value={perBack.view}
            ></textarea>
          </div>

          <div className="col-md-6">
            <h5>
              9. What values & qualities are you looking for in your next
              relationship ?
            </h5>
            <textarea
              onChange={(e) =>
                setPerBack({ ...perBack, expectation: e.target.value })
              }
              value={perBack.expectation}
            ></textarea>
          </div>

          <div className="col-md-6">
            <h5>
              10. Have you thought about your own qualities and values that make
              you a good spouse?
            </h5>
            <textarea
              onChange={(e) =>
                setPerBack({ ...perBack, ownqualities: e.target.value })
              }
              value={perBack.ownqualities}
            ></textarea>
          </div>

          <div className="col-md-6">
            <h5>
              11. Have you thought about your own flaws or areas that you need
              to improve on as a spouse?
            </h5>
            <textarea
              onChange={(e) =>
                setPerBack({ ...perBack, areas_to_improve: e.target.value })
              }
              value={perBack.areas_to_improve}
            ></textarea>
          </div>

          <div className="col-md-6">
            <h5>
              12. What makes you angry/upset? What constitutes crossing the line
              for you?
            </h5>
            <textarea
              onChange={(e) =>
                setPerBack({ ...perBack, crossing_the_line: e.target.value })
              }
              value={perBack.crossing_the_line}
            ></textarea>
          </div>
          <div className="col-md-6">
            <h5>13. Consultant's Observation</h5>
            <textarea
              onChange={(e) =>
                setPerBack({ ...perBack, other_details: e.target.value })
              }
              value={perBack.other_details}
            ></textarea>
          </div>
          <div className="col-md-6">
            {/* <div className="row"></div> */}
            <div className="row">
              <h5>
                14. Rank 1-5 your 5 love languages, 1 being the most and 5 being
                the least.
              </h5>
            </div>

            <div className="row">
              <div className="col col-sm-1">
                <input
                  type="text"
                  style={{ width: "25px", height: "25px", padding: "5px" }}
                  class="love-languages"
                  name="love_language_1"
                  id="lovelanguages1"
                  value={perBack.love_lang}
                  onChange={(e) => setPerBack({ ...perBack, love_lang: e.target.value })}
                />
              </div>
              <div className="col col-md-11 text-start">
                <label for="love_Languages_1">
                  Physical touch and affection
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col col-sm-1">
                <input
                  type="text"
                  style={{ width: "25px", height: "25px", padding: "5px" }}
                  class="love-languages"
                  name="love_language_2"
                  id="love-languages2"
                  value={perBack.love_lang_2}
                  onChange={(e) => setPerBack({ ...perBack, love_lang_2: e.target.value })}
                />
              </div>
              <div className="col col-md-11 text-start">
                <label for="loveLanguages4">Receiving gifts</label>
              </div>
            </div>

            <div className="row">
              <div className="col col-sm-1">
                <input
                  type="text"
                  style={{ width: "25px", height: "25px", padding: "5px" }}
                  class="love-languages"
                  name="love_language_3"
                  id="love-languages3"
                  value={perBack.love_lang_3}
                  onChange={(e) => setPerBack({ ...perBack, love_lang_3: e.target.value })}
                />
              </div>
              <div className="col col-md-11 text-start">
                <label for="loveLanguages2">Words of affirmation</label>
              </div>
            </div>

            <div className="row">
              <div className="col col-sm-1">
                <input
                  type="text"
                  style={{ width: "25px", height: "25px", padding: "5px" }}
                  class="love-languages"
                  name="love_language_4"
                  id="love-languages4"
                  value={perBack.love_lang_4}
                  onChange={(e) => setPerBack({ ...perBack, love_lang_4: e.target.value })}
                />
              </div>
              <div className="col col-md-11 text-start">
                <label for="loveLanguages5">Quality time</label>
              </div>
            </div>

            <div className="row">
              <div className="col col-sm-1">
                <input
                  type="text"
                  style={{ width: "25px", height: "25px", padding: "5px" }}
                  class="love-languages"
                  name="love_language_5"
                  id="love-languages5"
                  value={perBack.love_lang_5}
                  onChange={(e) => setPerBack({ ...perBack, love_lang_5: e.target.value })}
                />
              </div>
              <div
                className="col col-md-11 text-start"
                style={{ marginBottom: 50 }}
              >
                <label for="loveLanguages5">Acts of service</label>
              </div>
            </div>

            <Button onClick={handleSubmit}>submit</Button>
            <div className="status">{statusPerBack}</div>
            <div className="error">{errorPerBack}</div>

          </div>
        </div>
      </div>
    </>
  );
}
