import React, { useState, useEffect } from "react";
import Validation from "../components/LoginValidation";
import axios from "axios";
import { Image } from "react-bootstrap-v5";
import privateJet from "../private-jet2.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (errors.email === "" && errors.password === "") {
      const postData = {
        email: email,
        password: password,
      };
      const url = `${process.env.REACT_APP_API_URL}/login`;
      console.log("api url", url);

      axios
        .post(url, postData)
        .then((res) => {
          console.log(res);
          if (res.status !== 200) {
            setErrors({ email: "Invalid username or password" });
            return;
          }
          if (res.data.accessToken === undefined) {
            setErrors({ email: "No access token" });
            return;
          }
          const token = res.data.accessToken;

          localStorage.setItem("token", token);
          if (res.data.role === "user") {
            localStorage.setItem("userId", res.data.userId);
            localStorage.setItem("userName", res.data.name);
            localStorage.removeItem("staffName");
            localStorage.removeItem("staffId");
          } else {
            localStorage.setItem("staffName", res.data.name);
            localStorage.setItem("staffId", res.data.staffId);
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
          }
          const page = res.data.page;
          const homePage = `${process.env.REACT_APP_URL}/${page}`; // admin or staff take to search, user take to mutlistepform
          console.log("homePage url", homePage);
          window.location.href = homePage;
        })
        .catch((err) => {
          if (err.status === 401) {
            setErrors({ password: "invalid username or password2" });
          } else {
            setErrors({ password: err.message });
          }
        });
    }
  }, [errors]); // Watch for changes in the errors state

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(email, password);
    setErrors(Validation(email, password));
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center bg-dark vh-100 bgimage"
      style={{
        backgroundImage: `url(${privateJet})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="p-3 rounded bg-dark">
        <h4 className="text-light">DHF Login</h4>
        <form action="" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="text-light ">
              <strong>Email :</strong>
            </label>
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              onChange={(event) => setEmail(event.target.value)}
              className="form-control rounded-0"
            />
            {errors.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="password.text-light" className="text-light">
              <strong>Password</strong>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              className="form-control rounded-0"
            />
            {errors.password && (
              <span className="text-danger">{errors.password}</span>
            )}
          </div>

          <button type="submit" className="btn btn-success w-100 border">
            Log in
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
