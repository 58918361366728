import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Count({ item, icon, link, path }) {
  const [data, setData] = useState("...");

  useEffect(() => {
    var token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const leadsUrl = `${process.env.REACT_APP_API_URL}/dashboard/${path}`;

    console.log("leads url", leadsUrl);
    axios
      .get(leadsUrl, { headers })
      .then((res) => {
        console.log("this is the data", res.data);
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [item]);

  console.log(link);

  return (
    <div>
      <a
        href={link}
        className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-centre rounded"
        style={{ color: "black", textDecoration: "none" }}
      >
        <div className="webLeadsCount">
          <h3 className="">{data}</h3>
          <p className="">{item}</p>
        </div>
        <i className={icon}></i>
      </a>
    </div>
  );
}
