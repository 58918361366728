import React from "react";

export default function Hobbies({ nextStep, prevStep, maxSteps, step }) {
  return (
    <div className="container mt-5">
      <div className="footer">
        <button disabled={step === 0} onClick={prevStep}>
          prev
        </button>
        <button disabled={step === maxSteps} onClick={nextStep}>
          Next
        </button>
      </div>
    </div>
  );
}
