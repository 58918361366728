import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import { getReligionDropDown } from "../../model/UserProfile";

function Religion({ religionCallBack }) {
  const [display, setDisplay] = useState("religion");
  const [color, setColor] = useState("secondary");
  return (
    <Dropdown>
      <Dropdown.Toggle variant={color} id="dropdown-basic">
        {display}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {getReligionDropDown().map((item) => {
          return (
            <Dropdown.Item
              key={item.value}
              onClick={() => {
                religionCallBack(item.value);
                setDisplay(item.text);
                setColor(item.color);
              }}
            >
              {item.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Religion;
