import React, { useState, useEffect, useRef } from "react";
import { Form, FormControl, Button } from "react-bootstrap-v5";
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const searchInputRef = useRef(null);
  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(query);
    onSearch(query);
  };

  return (
    <Form className="d-flex justify-content-center" onSubmit={handleSearch}>
      <FormControl
        ref={searchInputRef}
        type="text"
        placeholder="Search"
        className="mr-sm-2 mySearch"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />{" "}
      &nbsp;
      <Button variant="outline-success" type="submit">
        <FaSearch />
      </Button>
    </Form>
  );
};

export default SearchBar;
