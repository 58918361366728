import React, { useState } from "react";
import "./CallLogForm.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CallLogForm({ onClose, leadId }) {
  const [status, setStatus] = useState(-1);
  const [log, setLog] = useState(null);
  const [intrest, setIntrest] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!log) {
      alert("log required");
      return;
    }
    console.log(status);
    if (status < 0) {
      alert("call status required");
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/addcalllogs`;
    console.log("api url", url);
    var token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", { replace: true });
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const postData = {
      lead_id: leadId,
      logby: localStorage.getItem("staffName"),
      call_status: status,
      interested: intrest,
      log: log,
      staff_id: localStorage.getItem("staffId"),
    };

    axios
      .post(url, postData, { headers })

      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          console.log(res);
          return;
        } else {
          onClose();
        }
      })
      .catch((err) => {
        console.log(err.response.data.error);
        alert(err.response.data.error);
      });
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <button className="close" onClick={onClose}>
          ×
        </button>
        <form>
          <div className="form-group">
            <input type="hidden" name="lead_id" value={leadId} />
          </div>
          <div className="form-group">
            <label htmlFor="callStatus">Call status:</label>
            <select
              name="callStatus"
              onChange={(e) => {
                console.log(e.target.value);
                setStatus(e.target.value);
              }}
            >
              <option value="-1">Select</option>
              <option value="0">Not Reachable</option>
              <option value="1">Connected</option>
              <option value="2">Asked to Callback</option>
              <option value="3">Asked Not to Call</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="log">Log:</label>
            <textarea
              name="log"
              id="log"
              onChange={(e) => {
                setLog(e.target.value);
              }}
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="interest">Interest:</label>
            <select
              name="interest"
              onChange={(e) => {
                setIntrest(e.target.value);
              }}
            >
              <option value>Select</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>

          {/* Add more form fields as needed */}
          <button
            onClick={(e) => {
              handleSubmit();
            }}
            type="submit"
            className="btn btn-primary"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
