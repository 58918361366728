import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../components/NavBar";
import ExpandableRow from "../components/ExpandableRow";
import { useLocation, useNavigate } from "react-router-dom"; // to redirect to login page if not logged in
import CallLogForm from "../components/CallLogForm";
import { Button } from "react-bootstrap-v5";

function Leads() {
  const location = useLocation();

  const [leads, setLeads] = useState([]);
  const [lead, setLead] = useState(null);

  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [backFlag, setBackFlag] = useState(true);
  const [totalPages, setTotalPages] = useState(50);
  const [showPopup, setShowPopup] = useState(false);
  const [deletedRefreshList, setDeletedRefreshList] = useState(false);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const removePageParameter = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("page");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const addCallLogOpenPopup = (lead) => {
    console.log(lead);

    setLead(lead);
    setShowPopup(!showPopup);
  };

  const deleteWebsiteLeadByAdmin = (lead_id, lead_name) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${lead_name} ?`
    );
    if (!confirmed) return;
    setError(null);

    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const deleteURL = `${process.env.REACT_APP_API_URL}/websiteleads/${lead_id}`;
    console.log("Delete URL", deleteURL);
    axios
      .delete(deleteURL, { headers })
      .then((res) => {
        console.log("Lead deleted", res);
        setDeletedRefreshList(true);
      })
      .catch((err) => {
        console.log("Error deleting lead", err);
        setError(err.message);
      });
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    if (!token) {
      console.log("No token found. Redirecting to login page");
      navigate("/login", { replace: true });
    }
    console.log(backFlag);
    // handle back from call logs

    if (backFlag) {
      const params = new URLSearchParams(window.location.search);
      const param = params.get("page");
      console.log(params);
      if (param && param !== "1") {
        setBackFlag(false);
        // removePageParameter();
        console.log(param);
        setCurrentPage(parseInt(param));
        return;
      }
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const leadsUrl = `${process.env.REACT_APP_API_URL}/websiteleads?page=${currentPage}`;
    console.log("leads url", leadsUrl);
    axios
      .get(leadsUrl, { headers })
      .then((res) => {
        console.log("this is the data", res.data);
        setLeads(res.data.data);
        if (res.data.totalCountPages) {
          // only page 1 will have this
          setTotalPages(res.data.totalCountPages);
        }
      })
      .catch((err) => setError(err));
  }, [currentPage, deletedRefreshList]);

  return (
    <>
      <NavBar />
      {showPopup && (
        <CallLogForm onClose={addCallLogOpenPopup} leadId={lead.id} />
      )}
      <div className="d-flex vh-100 bg-white justify-content-center ">
        <div className="w-100 bg-white rounded p-3">
          <div className="row">
            <div>
              <h1 className="heading">Recent Website Leads</h1>
            </div>
            <div>
              <Button
                className="btn btn-sm"
                style={{ float: "right" }}
                href="/addlead"
              >
                add lead
              </Button>
            </div>
          </div>

          {error && <div className="error">{error}</div>}

          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>mobilenumber</th>
                <th>call logs</th>
                <th>profession</th>
                <th>message</th>
                <th>delete</th>
              </tr>
            </thead>
            <tbody>
              {leads.length > 0 &&
                leads.map((lead) => {
                  return (
                    <ExpandableRow
                      key={lead.id}
                      lead={lead}
                      lastpage={currentPage}
                      addCallLogOpenPopup={addCallLogOpenPopup}
                      handleDelete={deleteWebsiteLeadByAdmin}
                    />
                  );
                })}
            </tbody>
          </table>

          {totalPages ? (
            <div>
              <button
                style={{ borderRadius: "5px" }}
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </button>
              <button
                style={{ borderRadius: "5px" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>{currentPage}</span>
              <button
                style={{ borderRadius: "5px" }}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button
                style={{ borderRadius: "5px" }}
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </button>
              <span>Total Pages: {totalPages}</span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Leads;
