import React, { useState } from "react";

export default function HoverCell({ content }) {
  const [length, setLength] = useState(4);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
    setLength(content.length);

    // You can call your callback function here
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setLength(4);

    // You can call your callback function here
  };
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {content ? content.substring(0, length) : ""}
      {hovered ? "" : "..."}
    </div>
  );
}
