import React from "react";
import Count from "./dashboard/Count";
import RecentLeads from "./dashboard/RecentLeads";
import HoverCell from "./HoverCell";

export default function Home() {
  return (
    <div className="px-3">
      <div className="container-fluid">
        <div className="row g-3 my-2">
          <div className="col-md-4 p-1">
            <Count
              item="website-leads"
              icon="bi bi-person-add p-3 fs-1"
              link="/websiteleads"
              path="websiteleadscount"
            />
          </div>
          <div className="col-md-4 p-1">
            <Count
              item="leads"
              icon="bi bi-person-check p-3 fs-1"
              link="/search?status=2"
              path="leadscount"
            />
          </div>
          <div className="col-md-4 p-1">
            <Count
              item="clients"
              icon="bi bi-person-check-fill p-3 fs-1"
              link="/search?staus=97,98,99"
              path="clientscount"
            />
          </div>
        </div>
      </div>

      <RecentLeads />
    </div>
  );
}
