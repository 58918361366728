import axios from "axios";
import React from "react";
import NavBar from "../components/NavBar";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap-v5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function CallLogRow({ calllog, handleDelete }) {
  const [length, setLength] = useState(50);

  const handlerDelete = (callLogId) => {
    handleDelete(callLogId); // let parent component handle the delete to refresh list after delete
  };

  return (
    <tr key={calllog.id + calllog.created_at}>
      <td>{calllog.logby}</td>
      <td>
        {calllog.created_at.substring(0, 10) +
          " " +
          calllog.created_at.substring(11, 19)}
      </td>

      <td>{calllog.interested}</td>
      <td>
        {calllog.log ? calllog.log.substring(0, length) : ""}
        {calllog.log && calllog.log.length > 50 ? (
          <Button
            style={{ float: "right" }}
            className="btn btn-sm btn-success"
            onClick={() => {
              {
                length == 50 ? setLength(600) : setLength(50);
              }
            }}
          >
            ...
          </Button>
        ) : null}
      </td>
      <td>
        <Button
          onClick={(e) => handleDelete(calllog.id)}
          style={{
            background: "none",
            color: "gray",
            padding: "1px",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
}
