import React from "react";
import { useNavigate } from "react-router-dom"; // Assuming you're using React Router for routing

function Logout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Perform logout logic here
    // This might involve clearing local storage, removing tokens, etc.

    // For example, clear localStorage
    localStorage.removeItem("token");

    // Redirect the user to the login page or any other desired route
    navigate("/login");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <h1>Logout</h1>
      <p>Are you sure you want to logout?</p>
      <button onClick={handleLogout}>Logout</button>
      <br />
      <br />
      <p>
      <button onClick={handleBack}>Back</button>
      </p>
    </div>
  );
}

export default Logout;
