import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Image,
  Alert,
} from "react-bootstrap";
import "../App.css";
import axios from "axios";
import CountryForm from "../components/CountryForm";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    country: "",
    cellNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = "Name is required, ";
    if (!formData.gender) newErrors.gender = "Gender is required, ";
    if (!formData.country) newErrors.country = "Country is required, ";
    if (!formData.cellNumber)
      newErrors.cellNumber = "Cell number is required, ";
    if (formData.cellNumber.length <= 3)
      newErrors.cellNumber = "invalid number, ";
    if (!formData.email) newErrors.email = "Email is required, ";
    if (!formData.password) newErrors.password = "Password is required, ";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match, ";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmitted(true);
      setSubmitError(null);

      const url = `${process.env.REACT_APP_API_URL}/register`;
      console.log("api url", url);

      axios
        .post(url, formData)
        .then((res) => {
          console.log(res);

          if (res.status !== 200) {
            setErrors({ email: res.data.data });
            return;
          }
          if (res.data.accessToken === undefined) {
            setErrors({ email: "No access token" });
            return;
          }
          const token = res.data.accessToken;

          localStorage.setItem("token", token);
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("userId", res.data.userId);

          const profileUrl = `${process.env.REACT_APP_URL}/multistepform`;
          console.log("leads url", profileUrl);
          window.location.href = profileUrl;
        })
        .catch((err) => {
          console.log(err);
          setSubmitError(err.response.data.error);
        });
    }
  };

  const countryFlagSelected = (countryCode, country1) => {
    console.log(formData);
    console.log(countryCode);
    setFormData({ ...formData, cellNumber: countryCode, country: country1 });
  };

  return (
    <Container className="cursive">
      <span>
        <Image src="images/Gold-Logo.png" className="goldLogo" />
        <h1>Register Now:</h1>
      </span>

      <div className="error">{submitError}</div>

      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={!!errors.name}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGender">
            <Form.Label>Gender</Form.Label>
            <div>
              <Form.Check
                inline
                label="Female"
                name="gender"
                value="9500"
                type="radio"
                id="female"
                onChange={handleChange}
                isInvalid={!!errors.gender}
              />
              <Form.Check
                inline
                label="Male"
                name="gender"
                value="9501"
                type="radio"
                id="male"
                onChange={handleChange}
                isInvalid={!!errors.gender}
              />
            </div>
          </Form.Group>
        </Row>

        <CountryForm callback={countryFlagSelected} />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formCellNumber">
            <Form.Label>Cell Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your cell number"
              name="cellNumber"
              value={formData.cellNumber}
              onChange={handleChange}
              isInvalid={!!errors.cellNumber}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formEmail">
            <Form.Label>Email Address</Form.Label>
            {isError ? <div className="error">hello</div> : null}
            <Form.Control
              type="email"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              isInvalid={!!errors.password}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formConfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm your password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              isInvalid={!!errors.confirmPassword}
            />
          </Form.Group>
        </Row>

        <div className="error">
          {errors.name} {errors.cellNumber} {errors.gender} {errors.country}{" "}
          {errors.email} {errors.password} {errors.confirmPassword}
        </div>
        <div className="error">{submitError}</div>

        <br />
        <Button
          variant="primary"
          type="submit"
          style={{ float: "right", marginBottom: 20 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <div style={{ textAlign: "left" }}>Already have an account?</div>

        <span>
          <a href="/login" style={{ float: "left" }}>
            Log in
          </a>
        </span>
      </Form>
    </Container>
  );
};

export default Register;
