import React, { useEffect, useState } from "react";

import axios from "axios";
import { Spinner } from "react-bootstrap";
import HoverCell from "../HoverCell";
export default function RecentLeads() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    var token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const leadsUrl = `${process.env.REACT_APP_API_URL}/dasboard/recentactiveleads`;

    console.log("leads url", leadsUrl);
    setLoading(true);
    axios
      .get(leadsUrl, { headers })
      .then((res) => {
        console.log("this is the data", res.data);
        setData(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <div className="sizeControl">
        <hr className="separator"></hr>
      </div>

      <h3 className="heading">Recently logged in leads</h3>
      {loading && <Spinner />}
      <table class="table  bg-white rounded-top ">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Email</th>
            <th scope="col">Last Login</th>
          </tr>
        </thead>
        <tbody>
          {data.map((lead, index) => {
            const date = new Date(lead.updated_at);
            const options = { day: "2-digit", month: "short", year: "numeric" };
            const formattedDate = date
              .toLocaleDateString("en-GB", options)
              .replace(/\./g, "");
            return (
              <tr>
                <td>
                  <a
                    href={`https://app.datehighflyers.com/admin/viewprofile/${lead.id}`}
                    target="_blank"
                  >
                    {lead.name_string}
                  </a>
                </td>
                <td>
                  <HoverCell content={lead.mobile_number} />
                </td>
                <td style={{ width: "30%" }}>
                  <HoverCell content={lead.email} />
                </td>
                <td>{formattedDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
