import Dropdown from "react-bootstrap/Dropdown";
import { getProfessionDropDown } from "../../model/UserProfile";
import { useState } from "react";

function Profession({ professionChange }) {
  const [display, setDisplay] = useState("Profession");
  const [color, setColor] = useState("secondary");
  return (
    <Dropdown>
      <Dropdown.Toggle variant={color} id="dropdown-basic">
        {display}
      </Dropdown.Toggle>

      <Dropdown.Menu className="professionDropDown">
        {getProfessionDropDown().map((item) => {
          return (
            <Dropdown.Item
              key={item.value}
              onClick={(e) => {
                professionChange(item.value);
                setDisplay(item.text);
                setColor(item.color);
              }}
            >
              {item.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Profession;
