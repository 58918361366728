import React, { useState, useRef, useEffect } from "react";
//import "./CallLogForm.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { InputGroup } from "react-bootstrap-v5";

export default function AddLead() {
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [confirmed, setConfirmed] = useState(null);
  const [mobilenumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");

  const [message, setMessage] = useState("");

  const [profession, setProfession] = useState("");

  const navigate = useNavigate();

  const inputRef = useRef(null);

  // Use useEffect to focus the input field when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  function validate(params) {
    if (!name) {
      setError("name required");
      return false;
    }

    if (!email) {
      setError("email required");
      return false;
    }

    if (!mobilenumber) {
      setError("mobile number required");
      return false;
    }

    if (!message) {
      setError("message required");
      return false;
    }

    if (!profession) {
      setError("profession required");
      return false;
    }
    return true;
  }

  const handleSubmit = (e) => {
    if (!validate()) {
      e.preventDefault();
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/addlead`;
    console.log("api url", url);
    var token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", { replace: true });
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const postData = {
      firstname: name,
      mobilenumber: mobilenumber,
      email: email,
      message: message,
      profession: profession,
    };

    axios
      .post(url, postData, { headers })
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          console.log(res);
          return;
        } else {
          setError(null);
          setConfirmed("lead inserted");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <h1 className="heading">Add Lead</h1>
        <div className="error">{error}</div>
        <div className="confirmed">{confirmed}</div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="form-group">
            <label htmlFor="name" style={{ textAlign: "left", color: "green" }}>
              Name :
            </label>
            <input
              className="form-control"
              id="name"
              placeholder="Enter Name"
              ref={inputRef}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="exampleInputEmail1"
              style={{ textAlign: "left", color: "green" }}
            >
              Email address :
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="mobilenumber"
              style={{ textAlign: "left", color: "green" }}
            >
              Mobile Number:
            </label>
            <input
              className="form-control"
              id="name"
              placeholder="Enter Mobile Number"
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="message"
              style={{ textAlign: "left", color: "green" }}
            >
              Message :
            </label>
            <textarea
              placeholder="Enter message"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>

          <div className="form-group">
            <label
              htmlFor="profession"
              style={{ textAlign: "left", color: "green" }}
            >
              profession :
            </label>
            <input
              className="form-control"
              id="name"
              placeholder="Enter Profession"
              onChange={(e) => setProfession(e.target.value)}
            />
          </div>

          <button type="submit" className="btn btn-success">
            Create Lead
          </button>
        </form>
      </div>
    </>
  );
}
