import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "./formcomponents/Spinner";

export default function AboutMe({
  nextStep,
  prevStep,
  maxSteps,
  step,
  formData,
  setFormData,
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dayValidation, setDayValidation] = useState(false);
  const [yearValidation, setYearValidation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // if no register token take back to register page
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/register", { replace: true });
    }

    // if user has already entered profile - get data from server
    const userId = localStorage.getItem("userId");
    const url = `${process.env.REACT_APP_API_URL}/aboutme/${userId}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          setFormData(res.data);
          console.log("formData", formData);
        } else {
          console.log("No data found on server");
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.error);
      });
  }, []);

  const validateAge = (day, month, year) => {
    const today = new Date();
    const birthDate = new Date(year, month - 1, day);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    if (age < 18 || age > 90) {
      return false;
    }
    return true;
  };

  const validateDay = (day) => {
    return day >= 1 && day <= 31;
  };

  const handleSaveOnNext = () => {
    setErrorMessage(null);
    setLoading(true);

    const { day, month, year } = formData.dob;

    if (!validateDay(day)) {
      setErrorMessage("Day must be between 1 and 31");
      setLoading(false);
      return;
    }

    if (!validateAge(day, month, year)) {
      setErrorMessage("Age must be between 18 and 90");
      setLoading(false);
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/register", { replace: true });
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const userId = localStorage.getItem("userId"); // used when admin/staff updating user profile else take role from token
    const url = `${process.env.REACT_APP_API_URL}/aboutme`;
    console.log("api url", url);

    const postData = {
      user_id: userId, // used when admin/staff updating user profile else take role from token
      marital_status: formData.marital_status,
      relationship_goal: formData.relationship_goal,
      dob: formData.dob,
      ethnicity: formData.ethnicity,
      ethnicityOthers: formData.ethnicityOthers,
      education: formData.education,
      educationOthers: formData.educationOthers,
      diet: formData.diet,
      gender: formData.gender,
      mobile_number: formData.mobile_number,
      alternate_email: formData.alternate_email,
      username: formData.username,
      about_me: formData.about_me,
    };
    console.debug("postData", postData);

    axios
      .post(url, postData, { headers })

      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.status !== 200) {
          console.log(res);
          setErrorMessage(res.data.error);
          return;
        } else {
          nextStep(); // go to next step on MultiStepForm
        }
      })
      .catch((err) => {
        console.log(err.response.data.error);
        setLoading(false);
        setErrorMessage(err.response.data.error);
      });
  };

  const handleDobChange = (e, field) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      dob: { ...formData.dob, [field]: value },
    });

    if (field === "day" && !validateDay(value)) {
      setDayValidation(true);
    } else {
      setDayValidation(false);
    }
  };

  console.log("formData", formData);

  // Ensure formData has default values to avoid undefined
  const defaultFormData = {
    marital_status: formData.marital_status || "",
    relationship_goal: formData.relationship_goal || "",
    dob: formData.dob || { day: "", month: "", year: "" },
    ethnicity: formData.ethnicity || "",
    ethnicityOthers: formData.ethnicityOthers || "",
    education: formData.education || "",
    educationOthers: formData.educationOthers || "",
    diet: formData.diet || "",
    dietOthers: formData.dietOthers || "",
    gender: formData.gender || "",
    mobile_number: formData.mobile_number || "",
    alternate_email: formData.alternate_email || "",
    username: formData.username || "",
    about_me: formData.about_me || "",
  };

  console.log("defaultFormData", defaultFormData);

  return (
    <div className="container mt-5">
      <div className="error">{errorMessage}</div>
      {loading && <Spinner />}
      <form>
        {/* MARITAL STATUS */}
        <div className="form-group">
          <label htmlFor="marital_status">Marital Status</label>
          <select
            className="form-control"
            id="marital_status"
            value={defaultFormData.marital_status}
            onChange={(e) =>
              setFormData({ ...formData, marital_status: e.target.value })
            }
          >
            <option value="">Please Select</option>
            <option value="9000">Never Married</option>
            <option value="9001">Widowed</option>
            <option value="9002">Separated</option>
            <option value="9003">Divorced</option>
          </select>
        </div>

        {/* RELATIONSHIP GOAL */}
        <div className="form-group">
          <label htmlFor="relationship_goal">Relationship Goal</label>
          <select
            className="form-control"
            id="relationship_goal"
            value={defaultFormData.relationship_goal}
            onChange={(e) =>
              setFormData({ ...formData, relationship_goal: e.target.value })
            }
          >
            <option value="">Please Select</option>
            <option value="9100">Friendship</option>
            <option value="9101">Companionship</option>
            <option value="9102">Marriage</option>
          </select>
        </div>

        {/* DATE OF BIRTH */}
        <label
          htmlFor="dob"
          style={{ display: "block", textAlign: "center", marginBottom: 10 }}
        >
          Date of Birth
        </label>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <input
            type="text"
            style={{
              flex: 1,
              maxWidth: "20%",
              marginRight: 10,
              textAlign: "center",
            }}
            placeholder="Day"
            value={defaultFormData.dob.day}
            onChange={(e) => handleDobChange(e, "day")}
          />
          <select
            className="form-control"
            style={{
              flex: 1,
              maxWidth: "20%",
              marginRight: 10,
              textAlign: "center",
            }}
            id="monthInput"
            value={defaultFormData.dob.month}
            onChange={(e) => handleDobChange(e, "month")}
          >
            <option value="">Month</option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <input
            type="text"
            style={{ flex: 1, maxWidth: "20%", textAlign: "center" }}
            placeholder="Year"
            value={defaultFormData.dob.year}
            onChange={(e) => handleDobChange(e, "year")}
          />
        </div>

        {/* ETHNICITY */}
        <div className="form-group">
          <label htmlFor="ethnicity">Ethnicity</label>
          <select
            className="form-control"
            id="ethnicity"
            value={defaultFormData.ethnicity}
            onChange={(e) =>
              setFormData({ ...formData, ethnicity: e.target.value })
            }
          >
            <option value="">Please Select</option>
            <option value="9200">Caucasian</option>
            <option value="9201">Arab</option>
            <option value="9202">Chinese</option>
            <option value="9203">Eurasian</option>
            <option value="9204">Korean</option>
            <option value="9205">Indian</option>
            <option value="9206">Japanese</option>
            <option value="9207">Malay</option>
            <option value="9208">Others</option>
          </select>
          <input
            hidden={defaultFormData.ethnicity !== "9208"}
            type="text"
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, ethnicityOthers: e.target.value })
            }
            value={defaultFormData.ethnicityOthers}
          />
        </div>

        {/* EDUCATION */}
        <div className="form-group">
          <label htmlFor="education">Education</label>
          <select
            className="form-control"
            id="education"
            value={defaultFormData.education}
            onChange={(e) =>
              setFormData({ ...formData, education: e.target.value })
            }
          >
            <option value="">Please Select</option>
            <option value="9300">Ph.D</option>
            <option value="9301">Masters</option>
            <option value="9302">Bachelors</option>
            <option value="9303">Diploma</option>
            <option value="9304">Others</option>
          </select>
          <input
            hidden={defaultFormData.education !== "9304"}
            type="text"
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, educationOthers: e.target.value })
            }
            value={defaultFormData.educationOthers}
          />
        </div>

        {/* DIET */}
        <div className="form-group">
          <label htmlFor="diet">Diet</label>
          <select
            className="form-control"
            id="diet"
            value={defaultFormData.diet}
            onChange={(e) => setFormData({ ...formData, diet: e.target.value })}
          >
            <option value="">Please Select</option>
            <option value="9400">Gluten Free</option>
            <option value="9401">Halal</option>
            <option value="9402">Kosher</option>
            <option value="9403">Non-Vegetarian</option>
            <option value="9404">Pescatarian</option>
            <option value="9405">Vegetarian</option>
            <option value="9406">Vegan</option>
            <option value="9407">Others</option>
          </select>
          <input
            hidden={defaultFormData.diet !== "9407"}
            type="text"
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, dietOthers: e.target.value })
            }
            value={defaultFormData.dietOthers}
          />
        </div>

        {/* GENDER */}
        <div className="form-group">
          <label htmlFor="gender">Gender</label>
          <select
            className="form-control"
            id="gender"
            value={defaultFormData.gender}
            onChange={(e) =>
              setFormData({ ...formData, gender: e.target.value })
            }
          >
            <option value="">Please Select</option>
            <option value="9500">Female</option>
            <option value="9501">Male</option>
          </select>
        </div>

        {/* MOBILE NUMBER */}
        <div className="form-group">
          <label htmlFor="mobile_number">Mobile Number</label>
          <input
            type="text"
            className="form-control"
            id="mobile_number"
            value={defaultFormData.mobile_number}
            onChange={(e) =>
              setFormData({ ...formData, mobile_number: e.target.value })
            }
          />
        </div>

        {/* ALTERNATE EMAIL */}
        <div className="form-group">
          <label htmlFor="altEmail">Alternate Email</label>
          <input
            type="email"
            className="form-control"
            id="altEmail"
            value={defaultFormData.alternate_email}
            onChange={(e) =>
              setFormData({ ...formData, alternate_email: e.target.value })
            }
          />
        </div>

        {/* USER NAME
        <div className="form-group">
          <label htmlFor="username">User Name</label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={defaultFormData.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
          />
        </div> */}

        {/* ABOUT YOURSELF */}
        <div className="form-group">
          <label htmlFor="about">Write up to 100 words about yourself</label>
          <textarea
            className="form-control"
            id="about"
            rows="3"
            maxLength="600"
            value={defaultFormData.about_me}
            onChange={(e) =>
              setFormData({ ...formData, about_me: e.target.value })
            }
          />
        </div>
      </form>
      <div className="error">{errorMessage}</div>
      {loading && <div className="loading">Saving...</div>}
      <div className="footer">
        <button disabled={step === 0} onClick={prevStep}>
          prev
        </button>
        <button disabled={step === maxSteps} onClick={handleSaveOnNext}>
          Next
        </button>
      </div>
    </div>
  );
}
