import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap-v5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";

export default function InfoCallLeads() {
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    var token = localStorage.getItem("token");
    if (!token) {
      console.log("No token found. Redirecting to login page");
      navigate("/login", { replace: true });
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const leadsUrl = `${process.env.REACT_APP_API_URL}/infocallleads?page=${currentPage}`;
    console.log("leads url", leadsUrl);
    axios
      .get(leadsUrl, { headers })
      .then((res) => {
        console.log("this is the data", res.data);
        setLeads(res.data.data);
        if (res.data.totalCountPages) {
          // only page 1 will have this
          setTotalPages(res.data.totalCountPages);
        }
      })
      .catch((err) => setError(err));
  }, []);

  return (
    <div>
      <NavBar />
      <span>
        <h1 className="heading">
          Info Call Leads{" "}
          <Button
            className="btn btn-sm"
            style={{ float: "right", margin: 9, padding: 9 }}
            href="/addinfocalllead"
          >
            Add Info Call Lead
          </Button>
        </h1>
      </span>

      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Enquiry</th>
            <th>Outcome</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {leads.length > 0 &&
            leads.map((lead) => {
              const date = new Date(lead.date);
              const options = {
                day: "2-digit",
                month: "short",
                year: "numeric",
              };
              const formattedDate = date
                .toLocaleDateString("en-GB", options)
                .replace(/\./g, "");

              const editLink = `/editinfocalllead/${lead.id}`;

              return (
                <tr key={lead.id}>
                  <td>{formattedDate}</td>
                  <td>{lead.name}</td>
                  <td>{lead.enquiry}</td>
                  <td>{lead.outcome}</td>
                  <td>{lead.phone_number}</td>
                  <td>{lead.email}</td>
                  <td>
                    <a href={editLink} style={{ color: "green" }}>
                      <FontAwesomeIcon icon={faPencilSquare} />
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
