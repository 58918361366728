import { RangeSlider } from "react-double-range-slider";
import "./AgeRangeSlider.css";
import { useState } from "react";
import { Button } from "react-bootstrap-v5";

function AgeRangeSlider({ ageRangeCallback }) {
  const [ageRange, setAgeRange] = useState({
    min: 18,
    max: 100,
    min_DOB: null,
    max_DOB: null,
  });
  const [showComponent, setShowComponent] = useState(false);
  const [color, setColor] = useState("secondary");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const toggleComponent = () => {
    if (!showComponent) {
      console.log("resetFilter");
      ageRangeCallback(null, null);
    } else {
      console.log("setFilter");
    }
    setShowComponent(!showComponent);
    setColor("primary");
  };

  const calculateDate = (age) => {
    const today = new Date();

    const DOB = new Date(
      today.getFullYear() - age,
      today.getMonth(),
      today.getDate()
    );

    return formatDate(DOB);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleChange = (value) => {
    const minDOB = calculateDate(value.max);
    const maxDOB = calculateDate(value.min);
    setAgeRange(value);
    ageRangeCallback(minDOB, maxDOB);
  };

  return (
    <div>
      <Button
        onClick={toggleComponent}
        variant={showComponent ? "primary" : "secondary"}
      >
        {showComponent ? "Hide Age Range" : "Show Age Range"}
      </Button>
      {showComponent && (
        <>
          <RangeSlider
            step={1}
            tooltipVisibility="always"
            tooltipPosition="over"
            value={{ min: 18, max: 100 }}
            defaultValue={{ min: 18, max: 65 }}
            onChange={handleChange}
          />
        </>
      )}
    </div>
  );
}

export default AgeRangeSlider;
