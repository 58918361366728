import React, { useEffect, useState } from "react";
import "./UpdateStatus.css";
import StatusEdit from "./dropdowns/StatusEditDropdows";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CheckToken from "../utils/CheckToken";

export default function UpdateStatus({ onClose, user }) {
  const [newStatus, setNewStatus] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  console.log(user);

  const headers = CheckToken();
  useEffect(() => {
    var url = `${process.env.REACT_APP_API_URL}/audittrail/${user.id}`;
    console.log("api url", url);
    axios
      .get(url, { headers })
      .then((res) => {
        setData(res.data);
        console.log("data after res: " + data);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, []);

  const changeStatus = () => {
    const headers = CheckToken();
    if (headers == null) {
      navigate("/login", { replace: true });
    }

    const postData = {
      status: newStatus,
    };

    var url = `${process.env.REACT_APP_API_URL}/editstatus/${user.id}`;
    console.log("api url", url);

    axios
      .post(url, postData, { headers })
      .then((res) => {
        console.debug(res);
        if (res.status !== 200) {
          setError("infocalllead not inserted");
          return;
        } else {
          setError(null);

          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
      });
  };
  console.log("data: " + data);
  return (
    <div className="popup" style={{ height: "300px", width: "600px" }}>
      <div className="popup-inner">
        <button className="close" onClick={onClose}>
          ×
        </button>
        <form style={{ paddingTop: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Name */}
            <div style={{ flex: 1 }}>{user.name_string}&nbsp;:</div>

            {/* StatusEdit */}
            <div style={{ flex: 1, textAlign: "center" }}>
              <StatusEdit
                role_id={user.role_id}
                statusCallBack={(val) => {
                  setNewStatus(val);
                }}
              />
            </div>

            {/* Change Button */}
            <div style={{ flex: 1 }}>
              <button
                onClick={(e) => {
                  changeStatus();
                }}
                type="submit"
                className="btn btn-secondary"
              >
                Change
              </button>
            </div>
          </div>
        </form>
        <hr />
        <div class="row">
          <table>
            <thead>
              <th style={{ fontWeight: "normal" }}>Date</th>

              <th style={{ fontWeight: "normal" }}>Updated status</th>
            </thead>
            <tbody>
              {data.data
                ? data.data.map((item) => {
                    return (
                      <tr>
                        <td>{item.datetime}</td>

                        <td>{item.changemade}</td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
            ;
          </table>
        </div>
      </div>
    </div>
  );
}
