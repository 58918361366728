import axios from "axios";
import React from "react";
import NavBar from "../components/NavBar";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap-v5";
import CallLogForm from "../components/CallLogForm";
import CallLogRow from "../components/CallLogRow";

export default function CallLogs() {
  const [queryParam, setQueryParam] = useState("");
  const [callLogs, setCallLogs] = useState([]);
  const [error, setError] = useState(null);
  const [deletedRefreshList, setDeletedRefreshList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [leadIdForPopupForm, setLeadIdForPopupForm] = useState(null);

  const navigate = useNavigate();

  const leadId = useParams();
  useEffect(() => {
    var token = localStorage.getItem("token");
    if (!token) {
      console.log("No token found. Redirecting to login page");
      navigate("/login", { replace: true });
    }
    const params = new URLSearchParams(window.location.search);
    const param = params.get("lastpage");
    if (param) {
      setQueryParam(param);
    }

    const name = params.get("name");
    setName(name);

    console.log(leadId.id);
    setLeadIdForPopupForm(leadId.id);
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const callLogsUrl = `${process.env.REACT_APP_API_URL}/calllogs/${leadId.id}`;
    console.log("call logs url", callLogsUrl);
    axios
      .get(callLogsUrl, { headers })
      .then((res) => {
        console.log("this is the data", res.data.data);
        setCallLogs(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
        setError(err.message);
      });
  }, [deletedRefreshList]);

  const handleDelete = (callLogId) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${callLogId} ?`
    );
    if (!confirmed) return;
    setError(null);

    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const deleteURL = `${process.env.REACT_APP_API_URL}/calllogs/${callLogId}`;
    console.log("Delete URL", deleteURL);
    axios
      .delete(deleteURL, { headers })
      .then((res) => {
        console.log("Lead deleted", res);
        setDeletedRefreshList(true);
      })
      .catch((err) => {
        console.log("Error deleting lead", err);
        setError(err.message);
      });
  };

  const handleClick = () => {
    const callLogsURL = `${process.env.REACT_APP_URL}/websiteLeads?page=${queryParam}`;
    console.log("Call Logs URL", callLogsURL);
    window.location.href = callLogsURL;
  };

  return (
    <div>
      <NavBar />

      <button
        onClick={() => handleClick()}
        style={{ float: "left", marginLeft: "20px" }}
      >
        Back
      </button>
      <div className="d-flex vh-100 bg-white justify-content-center ">
        <div className="w-100 bg-white rounded p-3">
          <h1 className="heading">{name}</h1>
          <button
            style={{ float: "right" }}
            onClick={() => setShowPopup(!showPopup)}
          >
            Add call log
          </button>
          {showPopup && (
            <CallLogForm
              onClose={() => setShowPopup(!showPopup)}
              leadId={leadIdForPopupForm}
            />
          )}
          <br />

          <div className="error">{error}</div>

          <table className="table">
            <thead>
              <tr>
                <th>Log by</th>
                <th>Created at</th>
                <th>interested</th>
                <th>Log</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {callLogs.length > 0 &&
                callLogs.map((calllog) => {
                  return (
                    <CallLogRow calllog={calllog} handleDelete={handleDelete} />
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
