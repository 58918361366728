import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { getMaritalStatusForDropDown } from "../../model/UserProfile";

function MaritalStatus({ maritalCallback }) {
  const [display, setDisplay] = useState("Marital status");
  const [color, setColor] = useState("secondary");
  return (
    <Dropdown>
      <Dropdown.Toggle variant={color} id="dropdown-basic">
        {display}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {getMaritalStatusForDropDown().map((item) => {
          return (
            <Dropdown.Item
              key={item.value}
              onClick={() => {
                maritalCallback(item.value);
                setDisplay(item.text);
                setColor(item.color);
              }}
            >
              {item.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
/*
9000 - never married
9001 - widowed
9002 - seperated
9003 - divorced
*/
export default MaritalStatus;
