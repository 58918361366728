import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import { getEthnicityDropDown } from "../../model/UserProfile";

function Ethnicity({ ethnicityCallback }) {
  const [display, setDisplay] = useState("Ethnicity");
  const [color, setColor] = useState("secondary");
  return (
    <Dropdown>
      <Dropdown.Toggle variant={color} id="dropdown-basic">
        {display}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {getEthnicityDropDown().map((item) => {
          return (
            <Dropdown.Item
              key={item.value}
              onClick={() => {
                ethnicityCallback(item.value);
                setDisplay(item.text);
                setColor(item.color);
              }}
            >
              {item.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Ethnicity;
