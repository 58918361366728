import Dropdown from "react-bootstrap/Dropdown";
import { getStatusDropDown } from "../../model/UserProfile";
import { useEffect, useState } from "react";

function Status({ statusCallBack, role_id }) {
  const [display, setDisplay] = useState("Status");
  const [color, setColor] = useState("secondary");

  return (
    <Dropdown>
      <Dropdown.Toggle variant={color} id="dropdown-basic">
        {display}
      </Dropdown.Toggle>

      <Dropdown.Menu className="statusDropDown">
        {getStatusDropDown().map((item) => {
          return (
            <Dropdown.Item
              key={item.value}
              value={role_id == item.value ? "selected" : ""}
              onClick={(e) => {
                statusCallBack(item.value);
                setDisplay(item.text);
                setColor(item.color);
              }}
            >
              {item.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Status;
